import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

const Section = ({
  title,
  children,
  center,
  big,
  full,
  bg,
  border,
  id,
  customStyles,
  noTextMargin,
}) => {
  return (
    <section
      className={cn(styles.container, [styles[bg]], {
        [styles.center]: center,
        [styles.big]: big,
        [styles.border]: border,
        [styles.noTextMargin]: noTextMargin,
      })}
      id={id}
      style={customStyles}
    >
      <div className={cn(styles.content)}>
        {title && (
          <div className="columns">
            <div className={cn('column is-10', 'is-offset-1')}>
              <h3 className={cn('title', styles.title)}>{title}</h3>
            </div>
          </div>
        )}
        <div className="columns">
          <div
            className={cn('column is-mobile', {
              'is-10 is-offset-1': !full,
              'is-12': full,
              [styles.center]: center,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
