import React from 'react'

import logoImg from './zebra-logo-512.png'

import styles from './styles.module.scss'

const Navbar = () => {
  return (
    <nav
      className="navbar is-white"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <a
            className="navbar-item"
            href="#"
            style={{
              fontSize: '2rem',
              fontFamily: '"Permanent Marker"',
              color: '#62bb45',
              letterSpacing: '1px',
            }}
          >
            <img
              src={logoImg}
              width="64"
              height="64"
              style={{
                maxHeight: '100%',
                marginRight: '20px',
              }}
            />
              <span className={styles.green}>Green</span>
              <span className={styles.zebra}>Zebra</span>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
