import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

import styles from './styles.module.scss'

const Feature = ({ title, text, icon, iconColor }) => (
  <div className="columns">
    <div className="column is-2">
      <Icon name={icon} style={{ fill: iconColor, width: '48px', height: '48px' }} />
    </div>

    <div className="column is-10">
      <h3 className="title is-4">{title}</h3>
      <p className="subtitle is-6">{text}</p>
    </div>
  </div>
)

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
}

Feature.defaultProps = {
  iconColor: '#000',
}

const FeaturesList = ({}) => (
  <section className={`container ${styles.featuresList}`}>
    <div className="columns">
      <div className="column">
        <Feature
          title="Innovative learning"
          text="Discover effective language learning techniques and tools that will greatly improve your studying process"
          icon="science"
          iconColor="#36be2a"
        />
      </div>
      <div className="column">
        <Feature
          title="Immersion"
          text="Immerse yourself in English-speaking environment without leaving your home and make English part of your daily life through engaging activities"
          icon="compass"
          iconColor="#6672ef"
        />
      </div>
      <div className="column">
        <Feature
          title="Long-term perspective"
          text="Build useful habits to keep improving in a natural and effective way after the end of the course"
          icon="telescope"
          iconColor="#ff9024"
        />
      </div>
    </div>
  </section>
)

FeaturesList.propTypes = {}

FeaturesList.defaultProps = {}

export default FeaturesList
