import React from 'react'

import Navbar from '../navbar'
import Button from '../Button'

import styles from './styles.module.scss'

const Hero = () => {
  return (
    <section className={`hero is-large ${styles.hero}`}>
      <Navbar />
      <div className="hero-body">
        <div className={`container ${styles.titles}`}>
          <h1 className="title">Are you stuck in learning English?</h1>
          <h2 className="subtitle">
            So you’re stuck at intermediate and it just doesn’t seem like you’re
            improving anymore. Maybe your level is even advanced according to
            tests, but the reality feels different. You don’t really understand
            much listening to native speakers. You struggle with native content.
            The vocabulary you use appears limited and primitive. It’s difficult
            to express your thoughts. You just don’t feel fluent and you’re
            losing hope.
          </h2>
          <div>
            <Button
              size="large"
              type="cta"
              style={{ marginRight: '20px' }}
              href="#learn-more"
            >
              Learn More
            </Button>
            {/* <Button
              type="cta"
              size="large"
              href="https://club.us12.list-manage.com/subscribe?u=ecfae5c602f5d1edffc43813f&id=cbe8500b2a"
            >
              Subscribe for free
            </Button> */}
          </div>
        </div>
      </div>
      <div className={styles.abstract} />
    </section>
  )
}

export default Hero
