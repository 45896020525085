import React from 'react'
import PropTypes from 'prop-types'

import Science from './svg/science.svg'
import Compass from './svg/circle-compass.svg'
import Telescope from './svg/telescope.svg'

const iconsMap = ({ name, ...props }) =>
  ({
    science: <Science {...props} />,
    compass: <Compass {...props} />,
    telescope: <Telescope {...props} />,
  }[name])

const Icon = (props) => {
  return iconsMap(props);
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Icon
