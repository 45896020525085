import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss';

const Button = ({ children, type, size, ...props }) => (
  <a className={`button is-${size} ${styles[type]}`} {...props}>
    {children}
  </a>
)

Button.propTypes = {
  type: PropTypes.oneOf(['basic', 'cta']),
  size: PropTypes.oneOf(['medium', 'large']),
}

Button.defaultProps = {
    type: 'basic',
    size: 'medium'
};

export default Button
