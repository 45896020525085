import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

const Heading = ({ children, center }) => {
  return (
    <h3
      className={cn('title is-3', styles.title, {
        [styles.center]: center,
      })}
    >
      {children}
    </h3>
  )
}

export default Heading
