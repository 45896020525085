import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import YoutubePlayer from 'react-youtube-player'

import './style.scss'

import Button from '../Button'
import Heading from '../Heading'

import Hero from '../Hero'
import FeaturesList from '../FeaturesList'
import Section from '../Section'
// import FeedbackSection from '../FeedbackSection'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'A free upper intermediate English course for adults that will help you improve your English and build useful habits.',
            },
            {
              name: 'keywords',
              content:
                'english course, english, course, english newsletter, education, language learning, learn english, self-study, green zebra english course',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Hero />
        <div className="container" style={{ marginTop: '32px' }}>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section border id="learn-more">
                <p className="featured-text">
                  You’re not alone! Many people at your level feel like they’ve
                  hit a plateau in learning. Feeling inadequate and shy while
                  talking to others, building sentences that sound unnatural,
                  lack of vocabulary and skills to articulate thoughts clearly,
                  trouble understanding fast speech, videos and TV shows without
                  subtitles are just some of the problems that language learners
                  all around the world face.
                </p>
                <p>
                  Additionally, you probably don't have too much time to study
                  and aren't sure how to do it efficiently. You might have heard
                  about creating immersive environment, but you lack motivation
                  to do so. You know that talking is the best way to improve
                  your speech, but even if you are confident enough to try it,
                  you just don't have people around to discuss advanced subjects
                  with. You want to enrich your vocabulary, but you don't know
                  where to find good sources, and even when you learn new words
                  you don't use them in your speech.
                </p>
                <p>
                  All the learners around the globe want to be fluent. They want
                  to be able to discuss different subjects with anyone without
                  feeling constrained by their limited vocabulary. They want to
                  speak beautifully and express complex thoughts freely. They
                  want to understand what other people say, regardless of their
                  accent and speed of speech. They want to consume original
                  content without difficulty, whether it’s watching videos or TV
                  shows, reading articles or even books. Is that your dream too?
                </p>
                <p>
                  If it is, here is the good news: it’s possible! And{' '}
                  <strong>you are in the right place to make it happen</strong>.
                  This free English course offers you a road map to improving
                  your listening, reading comprehension and speaking skills,
                  enriching your vocabulary and building the right habits that
                  will allow you to keep improving long after it’s over.
                </p>
              </Section>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column">
              <Section
                title="What's the idea?"
                bg="blue"
                center
                customStyles={{ marginTop: '-20px' }}
              >
                <p>
                  Green Zebra is a free course for adults. It will help you
                  immerse in English-speaking environment without leaving your
                  home and teach you effective learning techniques. It covers a
                  wide range of skills and activities that will ensure a
                  significant improvement of your fluency. If officially your
                  level is upper intermediate or advanced (B2-C1), but you face
                  the problems described above, it's for you.
                </p>
              </Section>
              <Section
                title="What happens after you subscribe?"
                bg="orange"
                center
                customStyles={{ marginTop: '-20px' }}
              >
                <p>
                  After a couple of introductory emails that will prepare you to
                  the course, you will start getting lessons with various tasks
                  once a week. The most efficient way to tackle them is to find
                  a little time to study every day. There will be no spam and
                  you can unsubscribe anytime.
                </p>
              </Section>
            </div>
            <div className="column">
              <Section
                title="What's in a lesson?"
                bg="green"
                id="whats-in-lesson"
                customStyles={{ marginTop: '80px' }}
              >
                <p>
                  <ul>
                    <li>
                      an interesting video dealing with subjects like education,
                      creativity, nature, technological advancements and others
                    </li>
                    <li>a list of advanced vocabulary from the video</li>
                    <li>questions about the video to answer</li>
                    <li>an inspiring article to read</li>
                    <li>a challenging riddle to solve</li>
                    <li> a slang expression, an idiom and a phrasal verb</li>
                    <li>another funny video to relax</li>
                  </ul>
                </p>
                <p>
                  You will also get access to a chat for English learners where
                  you can discuss tasks from the course or anything else. There
                  is a voice chat option there, allowing you to have live
                  conversations and work on your speaking skills.
                </p>
              </Section>
            </div>
          </div>
        </div>
        {/* <FeedbackSection /> */}
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1" />
          </div>
        </div>
        {/* <FeaturesList /> */}
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section title="How does it work?">
                <p>
                  Once you'll get a lesson you'll have a week to complete all
                  the tasks. The order in which you tackle them is up to you and
                  depends on how much time you have on a particular day.
                </p>
                <p>
                  You’ll look through the vocabulary and watch the video. This
                  will work both ways, allowing you to understand the video
                  better and memorize the new words easier as you will hear them
                  in context. Since all the vocabulary comes from the video,
                  you'll be learning real-life, advanced expressions that people
                  actually use in their speech.
                </p>
                <p>
                  You’ll then answer the questions aloud or discuss them with
                  other English learners to improve your speaking skills and see
                  how much you’ve understood. If you are too shy to speak at
                  this point, just talking to yourself will help you to practice
                  and build useful skills that you'll be able to use later in
                  real conversations.
                </p>
                <p>
                  You will keep working with the vocabulary during the week
                  using specific tips, effective tools and techniques you'll
                  learn about in your lessons.
                </p>
                <p>
                  The first video, questions and vocabulary are the main tasks
                  of the course. They are meant to improve your listening and
                  speaking skills, teach you to understand native speakers and
                  enrich your vocabulary. Take them seriously. Choose a day or
                  two when you have more time to study to watch the video and
                  answer the questions, since you might have to watch it several
                  times to understand it, especially in the beginning.
                </p>
                <p>
                  You’ll read the article, improving your reading comprehension
                  skills.
                </p>
                <p>
                  You’ll solve the riddle, engaging creative thinking and
                  strengthening your listening skills.
                </p>
                <p>
                  You’ll learn an idiom, a phrasal verb and a slang expression,
                  which will allow you to further enrich your vocabulary,
                  improve your understanding of native content and sound more
                  natural while speaking.
                </p>
                <p>
                  You’ll watch a funny video to relax and work on your listening
                  skills and understanding of native speakers once again.
                </p>
                <p>
                  If you are ambitions about practising and strengthening your
                  speaking skills, you'll use our chat or social media to find a
                  language partner to have live conversations with.
                </p>
                <p>
                  You will keep doing it for three months, gradually improving
                  all the skills mentioned above, becoming more comfortable with
                  English and building useful habits. It will require a lot of
                  determination from you, so you'll get some tips on staying
                  motivated and productive before the start of the course. If
                  you follow them, it will be easier to set up the right routine
                  and stick to it.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <Button
                    type="cta"
                    size="large"
                    href="https://club.us12.list-manage.com/subscribe?u=ecfae5c602f5d1edffc43813f&id=cbe8500b2a"
                  >
                    Subscribe for free!
                  </Button>
                </p>
              </Section>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section
                title="What results can you expect from the course?"
                bg="green"
              >
                <p>
                  The results largely depend on how much you are willing to do.
                  If you actually do all of the tasks, follow the instructions
                  and make an effort to study every day, by the end of the
                  course you will be able to understand native speakers with
                  different accents much better.
                </p>
                <p>
                  You'll be able to watch original videos in English without
                  subtitles. Reading will become easier and more pleasant. Your
                  active vocabulary will grow and you'll become better at
                  speaking. You will be able to discuss a wider range of
                  subjects in English, feeling more confident and using more
                  advanced words, including slang expressions, phrasal verbs and
                  idioms.
                </p>
                <p>
                  Most importantly though, you’ll build a useful habit that will
                  allow you to keep improving on your own. You’ll get used to
                  consuming original content in English every day and will
                  familiarize yourself with effective tools and techniques that
                  will help you to learn actively.
                </p>
              </Section>
            </div>
          </div>
        </div>
        <FeaturesList />
        <Section title="What our students say">
          <div className="container">
            <div className="columns">
              <div className="column">
                <Section
                  bg="blue"
                  center
                  customStyles={{ marginTop: '60px', padding: '40px 10px' }}
                  noTextMargin
                >
                  <p>
                    "I just want to say THANK YOU! All the stuff you are giving
                    us is downright amazing! I am definitely into Ankidroid, it
                    is fabulous! In addition, all the lessons are truly
                    engaging!
                    <br />
                    Keep it up!"
                    <br />
                    <em>javigeni</em>
                  </p>
                </Section>
                <Section
                  bg="orange"
                  center
                  customStyles={{ marginTop: '-40px', padding: '40px 10px' }}
                  noTextMargin
                >
                  <p>
                    "Everything you are sending us out is amazing! I am really
                    into the Anki cards (it's an awesome way to memorise the
                    vocabulary) . I really appreciate all that support you are
                    giving us. Thank you so much."
                    <br />
                    <em>Javier</em>
                  </p>
                </Section>
                {/* <Section
                  bg="green"
                  center
                  customStyles={{ marginTop: '-40px', padding: '40px 10px' }}
                  noTextMargin
                >
                  <p>
                    "I very much enjoyed the teaching style and innovative tools
                    for improving my memory! Thanks!"
                    <br />
                    <em>Karol</em>
                  </p>
                </Section> */}
              </div>
              <div className="column">
                <Section
                  bg="orange"
                  center
                  customStyles={{ marginTop: '-20px', padding: '40px 10px' }}
                  noTextMargin
                >
                  <p>
                    "I very much enjoyed the teaching style and innovative tools
                    for improving my memory! Thanks!"
                    <br />
                    <em>Karol</em>
                  </p>
                </Section>
                <Section
                  bg="green"
                  center
                  customStyles={{ marginTop: '-40px', padding: '40px 10px' }}
                  noTextMargin
                >
                  <p>
                    "I found the course entertaining, and Anki helped me a lot
                    with learning new words. I also liked that there were
                    different tasks: watching, reading, riddles and vocabulary
                    practice. It helped me improve and have fun :)"
                    <br />
                    <em>Natasha</em>
                  </p>
                </Section>
                <Section
                  bg="blue"
                  center
                  customStyles={{ marginTop: '-40px', padding: '40px 10px' }}
                  noTextMargin
                >
                  <p>
                    Your way of teaching and learning seems to be working! :) I
                    was a bit skeptical at first to be honest but took a leap of
                    faith and it was worth it. I have to say that it took a
                    while to see the results but when I think about my level
                    before the course, I see A LOT of progress. Thanks!
                    <br />
                    <em>Andrzej</em>
                  </p>
                </Section>
              </div>
            </div>
          </div>
        </Section>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section title="ADDITIONALLY...">
                <p>
                  If you need more support while learning, you can get the
                  Vocabulary Workbook as an addition to the course to make sure
                  you’ll memorize all the new words and have a more structured
                  learning process.
                </p>
              </Section>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section
                title="What's in the book?"
                bg="grey"
                id="whats-in-the-book"
              >
                <p>
                  <ul>
                    <li>12 engaging lessons as in the course</li>
                    <li>over 280 real-life, advanced expressions</li>
                    <li>links to videos where the vocabulary was taken from</li>
                    <li>
                      over 200 effective exercises to help you learn the new
                      words
                    </li>
                    <li>
                      interactive flashcards with all the words, including
                      pronunciation recordings, example sentences and pictures
                      (where possible) that will make learning the vocabulary
                      easier, faster and more effective
                    </li>
                    <li>
                      creative tasks that will engage your mind and teach you to
                      think in English
                    </li>
                    <li>
                      lots of revision to make sure you won’t forget the
                      previously learned vocabulary while working with the new
                      words
                    </li>
                    <li>answers to all the exercises</li>
                    <li>a final test to see how much you’ve learned</li>
                  </ul>
                </p>
                <p>
                  In addition to everything that the course itself provides, you
                  will also work with ready-made intelligent flashcards, do
                  effective exercises that will help you encounter the
                  vocabulary in different contexts and watch your progress.
                  Studying will be easier, more systematic and effective, since
                  all the activities aimed at learning and revising the
                  vocabulary will be prepared for you.
                </p>

                <p>
                  Timely repetition and tackling the new words from various
                  angles will ensure that they will become part of your active
                  vocabulary. This means you will actually start using them in
                  your speech.
                </p>
                <p>
                  To sum it up, you'll have to put less time and effort into
                  studying and will enjoy better results that you'll be able to
                  measure.
                </p>
                <p>
                  You will get the first lesson of the book for free when you
                  subscribe to the course so that you can see what it’s like
                  before you decide whether you need it. You will also get a 35%
                  discount.
                </p>
                <div style={{ textAlign: 'center', height: '420px' }}>
                  <YoutubePlayer
                    videoId="9q1-6qPrKwQ"
                    playbackState="unstarted"
                  />
                </div>
              </Section>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section title="Can you use the book alone?">
                <p>
                  Yes. It’s absolutely self-sufficient, and if you want to focus
                  on enriching your vocabulary, it’s the right tool for you.
                  Since you’ll find links to the videos where the vocabulary was
                  taken from in every lesson, you’ll be able to enjoy a
                  well-structured and extensive learning strategy that covers
                  listening to the vocabulary in context, working with
                  interactive flashcards, doing effective exercises, creative
                  tasks and revising the words you've learned before. You will
                  also be able to test yourself and see how much you've learned
                  when you finish the book.
                </p>
              </Section>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section title="Where can you get the book?" bg="grey" id="idea">
                <p>
                  <ul>
                    <li>
                      <a href="https://www.smashwords.com/books/view/787522">
                        Smashwords
                      </a>
                    </li>
                    <li>
                      <a href="https://www.amazon.com/dp/B07MWCNN7R">Amazon</a>
                    </li>
                    <li>
                      <a href="https://www.kobo.com/ww/en/ebook/green-zebra-vocabulary-exercise-book">
                        Kobo
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.barnesandnoble.com/w/advanced-english-vocabulary-workbook-green-zebra-english-
course/1130310380?ean=2940155127987"
                      >
                        Barnes & Noble
                      </a>
                    </li>
                    <li>
                      <a href="https://itunes.apple.com/pl/book/green-zebra-vocabulary-exercise-book/id1347385942?mt=11">
                        iTunes
                      </a>
                    </li>
                    <li>
                      and in other major retailers. Free samples are available.
                    </li>
                  </ul>
                </p>
              </Section>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section title="How much time do you have to spend studying every day?">
                <p>
                  The beauty of this approach is that it's up to you how much
                  time you can and want to spend studying. Of course, the more
                  you learn, the more efficient it is and the better results you
                  can enjoy faster, but it really doesn't have to be that much.
                  A little something every day is better that a lot of work once
                  a week.
                </p>
                <p>
                  The best strategy is to find a day or two during the week when
                  you have more time, eg. 30-60 minutes to work on the main
                  video and answer the questions. It will be enough to spend
                  about 5-15 minutes studying on the rest of the days. Depending
                  on how much time and energy you have, you can work with
                  interactive flashcards, do an exercise or two, watch a short,
                  funny video, read an article or solve a riddle. It’s important
                  that you do at least something every day though: this will
                  make your learning process much more effective and will ensure
                  you will form a habit of consuming materials in English and
                  learning actively. Even if you only have 5 minutes, use them
                  wisely! This will become the basis of your future success.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <Button
                    type="cta"
                    size="large"
                    href="https://club.us12.list-manage.com/subscribe?u=ecfae5c602f5d1edffc43813f&id=cbe8500b2a"
                  >
                    Subscribe for free!
                  </Button>
                </p>
              </Section>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Section title="THE ULTIMATE GOAL" bg="orange" center>
                <p>
                  After the course ends, you will keep consuming materials in
                  English every day and actively working on your speaking skills
                  and vocabulary, using the tools and techniques that you’ve
                  learned. It will be easier than before and will keep getting
                  more natural with practice. You will start consuming more and
                  more materials in English, gradually immersing yourself in
                  English-speaking environment. At a certain point most of the
                  information you consume will be in English, and you’ll be
                  doing it without difficulty. You will become a confident
                  speaker, and the range of subjects you’ll be able to discuss
                  will be as wide as in your native language. Your active
                  vocabulary will be constantly growing, allowing you to enjoy
                  even more materials in English and to speak like a native.
                  Needless to say this can only happen if you make a
                  considerable effort. You can start with a small step today!
                </p>
              </Section>
            </div>
          </div>
        </div>
        <div>
          <Heading center>Buy the book and learn even more!</Heading>
          <div
            className="smashwords-widget"
            data-type="single"
            data-items="book:787522"
            data-font="sans"
            data-ribboncolor="#4181c3"
            data-bgcolor="#fafafa"
            data-buttoncolor="#4181c3"
            style={{ width: '550px', height: '580px', margin: '0 auto' }}
            data-width="550"
            data-height="480"
            data-headline="Advanced English Vocabulary Workbook"
            data-body="Learn over 280 real-life, advanced expressions, enrich your vocabulary, improve your speaking skills and become more fluent in English with the help of effective exercises, creative tasks, interactive flashcards and revision. Watch original videos in English to hear the words you are learning used by a native speaker. The book can be used for self-studying as well as teaching."
          />
        </div>
        {/* <Section title="Pricing" big center full bg="grey">
          <div style={{ marginTop: '24px' }}>
            <div
              style={{
                height: '600px',
                backgroundColor: 'orange',
                width: '340px',
                // backgroundImage: 'url(https://sketchapp.com/images/pages/pricing/card-pricing-bg-individual-orange.svg)',
                backgroundColor: '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '10px',
                boxShadow: '-2px 2px 5px 1px rgba(0,0,0,0.15)',
                display: 'inline-block',
                marginRight: '32px',
              }}
            />
            <div
              style={{
                height: '600px',
                backgroundColor: 'orange',
                width: '340px',
                // backgroundImage: 'url(https://sketchapp.com/images/pages/pricing/card-pricing-bg-individual-orange.svg)',
                backgroundColor: '#f46236',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '10px',
                // boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.15)',
                boxShadow: 'rgba(0, 0, 0, 0.25) 2px 2px 7px 2px',
                display: 'inline-block',
                transform: 'scale(1.1)',
              }}
            />
            <div
              style={{
                height: '600px',
                backgroundColor: 'orange',
                width: '340px',
                // backgroundImage: 'url(https://sketchapp.com/images/pages/pricing/card-pricing-bg-individual-orange.svg)',
                backgroundColor: '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '10px',
                boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.15)',
                display: 'inline-block',
                marginLeft: '32px',
              }}
            />
          </div>
        </Section>
        <FeedbackSection />
        <Section title="Lorem ipsum" center big bg="blue" border>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            lectus arcu bibendum at varius. Sed vulputate mi sit amet mauris
            commodo quis imperdiet. Pretium viverra suspendisse potenti nullam
            ac. Rhoncus urna neque viverra justo. At urna condimentum mattis
            pellentesque id. In arcu cursus euismod quis viverra nibh cras.
          </p>
        </Section>
        <img src={image1} /> */}
        {/* <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div> */}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node, // .isRequired,
}

export default Layout
